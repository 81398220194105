import React from "react";

const WorkWithUs = () => {
  // useEffect(() => {
  //   let ctx = gsap.context(() => {
  //     // Create a timeline for animations
  //     const tl = gsap.timeline({
  //       scrollTrigger: {
  //         trigger: ".info-card-container", // Use an appropriate selector for the trigger
  //         start: "top 5%", // Pin the container 10% from the top
  //         pin: true,
  //         pinSpacing: false,
  //         scrub: true,
  //         // Add other ScrollTrigger options as needed
  //       },
  //     });

  //     // Animation for the first InfoCard
  //     tl.from(".animate-first", {
  //       opacity: 0,
  //       y: "20%",
  //       stagger: 0.2,
  //     })
  //       .from(".animate-second", {
  //         opacity: 0,
  //         y: "20%",
  //       })
  //       .from(".animate-third", {
  //         opacity: 0,
  //         y: "20%",
  //       });

  //     // Animation for the second InfoCard (similar animations for the third)
  //     tl.from(".animate-second", {
  //       opacity: 0,
  //       y: "20%",
  //     }).from(".animate-third", {
  //       opacity: 0,
  //       y: "20%",
  //     });

  //     // Repeat the above for the third InfoCard

  //     // Unpin the container when the animations are done
  //     tl.to(pinContainer.current, {
  //       scrollTrigger: {
  //         trigger: pinContainer.current,
  //         start: "bottom bottom",
  //         end: "+=100%", // Adjust the end value as needed
  //         pin: false,
  //         pinSpacing: false,
  //         scrub: true,
  //         // Add other ScrollTrigger options as needed
  //       },
  //     });
  //   }, pinContainer);

  //   return () => ctx.revert();
  // }, []);

  return (
    <div className="md:grid grid-cols-5 mx-5 md:mx-20 my-20">
      <div className="col-span-2 flex flex-col justify-center">
        <h2 className="text-4xl md:text-6xl mb-5">Work with us</h2>
        <p className="md:text-lg mb-10">
          We’re looking for talented individuals who want to make an impact.
          Begin the next phase of your career by joining our amazing team.
        </p>
        {/* <button className="w-52 px-8 py-4 rounded-xl text-white text-xl bg-primary">
            Careers
          </button> */}
      </div>
      <div className="col-span-3">
        <div className="md:ml-32 bg-black overflow-hidden h-64 md:h-[400px] rounded-xl">
          {/* <img src="" className="cover" alt="" /> */}
          <div
            className={`h-[120%] w-[120%] bg-no-repeat bg-cover bg-center`}
            style={{ backgroundImage: `url(workWithUs.avif)` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default WorkWithUs;
