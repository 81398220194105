import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import customerCareAnimation from "../../lottie/customerCare.json";
import sittingWashAnimation from "../../lottie/sittingWash.json";
import deliveryAnimation from "../../lottie/delivery.json";
import { IoShirtOutline } from "react-icons/io5";
import { HiOutlineCursorArrowRays } from "react-icons/hi2";
import { SlCalender } from "react-icons/sl";
import { MdOutlineDeliveryDining } from "react-icons/md";
import { BiSupport } from "react-icons/bi";

const InfoCards = () => {
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerRef = useRef();
  const firstInfoCardRef = useRef();

  useEffect(() => {
    const context = containerRef?.current;
    const infoCards = context.querySelectorAll(".info-card");

    gsap.registerPlugin(ScrollTrigger);

    const ctx = gsap.context(() => {
      if (!isMobile) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: context, // Use an appropriate selector for the trigger
            start: "top top", // Pin the container 10% from the top
            end: "+=6000px",
            pin: true,
            pinSpacing: true,
            scrub: true,
          },
        });

        gsap.set(infoCards, { position: "absolute" });

        infoCards.forEach((infoCard, index) => {
          console.log(infoCard);
          const topBoxes = infoCard.querySelectorAll(".animate-third-item");
          const animateSecond = infoCard.querySelector(".animate-second");
          const animateThird = infoCard.querySelector(".animate-third");

          tl.from(topBoxes, {
            opacity: 0,
            y: "50%",
            duration: 1,
            stagger: 0.3,
          })
            .from(
              animateSecond,
              {
                opacity: 0,
                y: "30%",
                duration: 1,
              },
              "-=0.5"
            )
            .from(animateThird, {
              opacity: 0,
              duration: 1,
            });

          if (index !== 2) {
            tl.to(
              infoCard,
              {
                opacity: 0,
                duration: 1,
                onComplete: () => {
                  infoCard.style.opacity = 0;
                },
              },
              ">="
            );
          }
        });
      }
    }, context);

    return () => ctx.revert();
  }, [isMobile]);

  const infoCards = [
    {
      top: [
        {
          icon: <IoShirtOutline />,
          content:
            "Never worry about staining your favourite shirt. We offer laundry, dry cleaning and ironing at a schedule that fits your lifestyle.",
        },
        {
          icon: <HiOutlineCursorArrowRays />,
          content:
            "Plan your day with ease. Choose a collection and delivery time at your convenience.",
        },
        {
          icon: <SlCalender />,
          content:
            "Your local washer will clean your items with utmost care. You're in full control of your delivery and can always reschedule.",
        },
      ],
      bottom: "Laundry service designed for you",
      animation: sittingWashAnimation,
      bg: "rgb(252 211 77)",
      ref: { firstInfoCardRef },
    },
    {
      top: [
        {
          icon: <MdOutlineDeliveryDining />,
          content:
            "Schedule a collection today and have your clothes picked up in less than 24 hours. Pickup and delivery is timely, all based on your convenience. No need to plan your day around a delivery, we can plan the delivery aound you.",
        },
      ],
      bottom: "No need to plan in advance",
      animation: deliveryAnimation,
      bg: "rgb(110 231 183)",
      ref: { firstInfoCardRef },
    },
    {
      top: [
        {
          icon: <BiSupport />,
          content:
            "Any change in delivery times or date, or if something goes wrong, we are here. Our online customer support team will assist you day and night.",
        },
      ],
      bottom: "We're here, whenever, wherever",
      animation: customerCareAnimation,
      bg: "rgb(253 186 116)",
      ref: { firstInfoCardRef },
    },
  ];

  return (
    <div
      className="w-full md:mb-[1000px] info-card-container relative"
      ref={containerRef}
    >
      {infoCards.map((card, index) => (
        <InfoCard
          backgroundColor={card.bg}
          bottom={card.bottom}
          index={index}
          key={index}
          ref={card.ref}
          top={card.top}
          animation={card.animation}
        />
      ))}
    </div>
  );
};

export default InfoCards;

const InfoCard = React.forwardRef(
  ({ index, bottom, top, backgroundColor, animation }, ref) => {
    return (
      <div
        className="w-full md:h-screen flex pt-[120px] top-0 bottom-0 left-0 right-0 info-card"
        ref={ref}
        style={{ zIndex: 10 - index }}
      >
        <div className="w-full pin-card max-w-[1920px] mx-auto">
          <div className="w-full h-full flex pb-[2%] px-5 md:px-[2%]">
            <div className="w-full rounded-xl pt-16 md:pt-[2.8%] max-sm:px-5 md:pl-[2.8%] pb-12 md:pb-[2.3%] md:pr-[42%] relative overflow-hidden">
              <div
                className="absolute top-0 right-0 left-0 bottom-0"
                style={{ backgroundColor }}
              ></div>
              <div className="md:absolute top-[10%] bottom-[5%] left-auto right-[8%] flex items-end justify-center animate-third max-sm:mb-14">
                <Lottie
                  animationData={animation}
                  loop={true}
                  className="max-h-full w-[550px]"
                  // style={{ height: 100, width: 100 }}
                />
              </div>
              <div className="md:h-full flex flex-col-reverse md:flex-col md:justify-between relative max-sm:gap-y-12">
                <div className="animate-first flex flex-col md:grid grid-cols-3 gap-7 max-w-[90%]">
                  {top.map((item) => (
                    <div
                      className={`${
                        top.length === 1 ? "col-span-2" : "col-span-1"
                      } animate-third-item`}
                    >
                      <div className="w-7 h-7 md:w-[2.25rem] md:h-[2.25rem] bg-black rounded-lg mb-[1rem] flex justify-center items-center text-white">
                        {item.icon}
                      </div>
                      <p className="text-sm md:text-base">{item.content}</p>
                    </div>
                  ))}
                </div>
                <div className="animate-second text-[1.8vh]">
                  <h2 className="text-[11vw] md:text-[6.875rem] leading-[.94]">
                    {bottom}
                  </h2>
                </div>
              </div>
              <div className="absolute top-auto left-auto right-[2.8%] bottom-[5%] flex flex-col gap-y-[1.25rem]">
                {[0, 1, 2].map((item) => (
                  <div
                    key={item}
                    className={`border border-black rounded-full h-1.5 w-1.5 ${
                      item === index ? "bg-black" : ""
                    }`}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
