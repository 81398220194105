import React from "react";

const AboutUs = () => {
  return (
    <div>
      <div className="min-h-[calc(100vh-10vh)] md:grid grid-cols-7 mb-10 z-10 md:px-6">
        <div className="col-span-3 flex flex-col justify-end pb-20 px-8 py-24 md:px-10">
          <h4 className="text-primary text-sm md:text-lg">About us</h4>
          <h2 className="text-black text-3xl md:text-7xl font-bold">About</h2>
          <h2 className="text-primary text-5xl md:text-9xl font-light mb-5 md:mb-12">
            Wasshh
          </h2>
          <p className="text-sm md:text-lg">
            We connect users to local laundromarts and dry cleaners near them by
            providing convenient pick-up and delivery scheduling. The Wasshh
            mobile application provides a simple and convenient way to find,
            book, and pay for laundry and dry cleaning services. Our mobile
            application offers various features that enable users to compare
            prices, read reviews, and select the best providers for their needs.
          </p>
        </div>
        <div className="col-span-4"></div>
      </div>
    </div>
  );
};

export default AboutUs;
