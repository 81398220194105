import { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Services = () => {
  const services = [
    {
      title: "Dry Clean",
      image: "serviceDryClean.avif",
    },
    {
      title: "Wash",
      image: "serviceWash.avif",
    },
    {
      title: "Iron",
      image: "serviceIron.avif",
    },
  ];

  const containerRef = useRef();

  useEffect(() => {
    const context = containerRef?.current;

    gsap.registerPlugin(ScrollTrigger);

    const ctx = gsap.context((self) => {
      const title = self.selector(".title");
      const subtitle = self.selector(".subtitle");
      const service = self.selector(".service");
      // if (!isMobile) {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: context, // Use an appropriate selector for the trigger
          start: "top center", // Pin the container 10% from the top
          // end: "+=6000px",
          // pin: true,
          // pinSpacing: true,
          scrub: true,
        },
      });

      tl.from(title, {
        opacity: 0,
        y: "100%",
        duration: 1,
        stagger: 0.3,
      })
        .from(
          subtitle,
          {
            opacity: 0,
            y: "100%",
            duration: 1,
            stagger: 0.3,
          },
          "-=0.5"
        )
        .from(
          service,
          {
            opacity: 0,
            y: "25%",
            duration: 2,
            stagger: 0.3,
          },
          "-=0.1"
        );
    }, context);

    return () => ctx.revert();
  }, []);

  return (
    <div className="my-32" ref={containerRef}>
      <div className="flex flex-col items-center mb-8">
        <h2 className="text-4xl mb-3 title">Our Services</h2>
        <p className="text-lg subtitle">A few of the services we render</p>
      </div>
      <div className="flex flex-col md:grid grid-cols-3 gap-8 mx-5 md:mx-32">
        {services.map((service, index) => (
          <ServiceItem service={service} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Services;

const ServiceItem = ({ service }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="h-[350px] cursor-pointer relative bg-black overflow-hidden rounded-xl service"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={`bg-cover transition-all duration-500 bg-center ${
          !hover ? "h-full w-full" : "h-[120%] w-[120%]"
        }`}
        style={{ backgroundImage: `url(${service.image})` }}
      ></div>
      <div className="absolute top-0 left-0 bottom-0 right-0 transition-all duration-500 bg-gradient-to-tr from-black to-transparent opacity-0 hover:opacity-100"></div>
      <div className="absolute bottom-8 left-8">
        <span className="text-4xl text-white">{service.title}</span>
      </div>
    </div>
  );
};
