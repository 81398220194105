import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";

const CountdownTimer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const countdownDate = new Date("2023-09-01T00:00:00").getTime();

  const timerRef = useRef();
  const prevValuesRef = useRef({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date().getTime();
      const timeLeft = countdownDate - now;

      if (timeLeft <= 0) {
        clearInterval(timerRef.current);
        return;
      }

      const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hoursLeft = Math.floor(
        (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutesLeft = Math.floor(
        (timeLeft % (1000 * 60 * 60)) / (1000 * 60)
      );
      const secondsLeft = Math.floor((timeLeft % (1000 * 60)) / 1000);

      // Animate only the numbers that change with GSAP
      animateNumberChange(
        ".countdown-days",
        prevValuesRef.current.days,
        daysLeft
      );
      animateNumberChange(
        ".countdown-hours",
        prevValuesRef.current.hours,
        hoursLeft
      );
      animateNumberChange(
        ".countdown-minutes",
        prevValuesRef.current.minutes,
        minutesLeft
      );
      animateNumberChange(
        ".countdown-seconds",
        prevValuesRef.current.seconds,
        secondsLeft
      );

      // Update state
      setDays(daysLeft);
      setHours(hoursLeft);
      setMinutes(minutesLeft);
      setSeconds(secondsLeft);

      // Update previous values
      prevValuesRef.current = {
        days: daysLeft,
        hours: hoursLeft,
        minutes: minutesLeft,
        seconds: secondsLeft,
      };
    };

    timerRef.current = setInterval(updateTimer, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(timerRef.current);
    // eslint-disable-next-line
  }, []);

  const animateNumberChange = (selector, current, next) => {
    if (current !== next) {
      const element = document.querySelector(selector);
      gsap.to(element, {
        y: -30,
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          gsap.fromTo(
            element,
            { y: 30, opacity: 0 },
            { y: 0, opacity: 1, duration: 0.5 }
          );
        },
      });
    }
  };

  return (
    <div className="flex flex-col items-center mx-5">
      <div className="mb-10">
        <h2 className="text-4xl text-center md:text-5xl lg:text-6xl">
          Countdown to launch
        </h2>
      </div>
      <div className="countdown-timer w-full px-5 md:px-10 lg:px-32 grid grid-cols-4 gap-4 md:gap-8 lg:gap-10">
        <div className="flex gap-x-2 md:gap-x-4">
          <div className="h-16 md:h-42 lg:h-52 border-2 md:border-[6px] lg:border-[8px] border-primary flex-1 flex justify-center items-center">
            <div className="countdown-number countdown-days text-primary text-3xl md:text-7xl lg:text-8xl font-light">
              {days}
            </div>
          </div>
          <span className="countdown-label md:text-lg uppercase font-bold text-primary">
            D
          </span>
        </div>
        <div className="flex gap-x-2 md:gap-x-4">
          <div className="h-16 md:h-42 lg:h-52 border-2 md:border-[6px] lg:border-[8px] border-primary flex-1 flex justify-center items-center">
            <div className="countdown-number countdown-hours text-primary text-3xl md:text-7xl lg:text-8xl font-light">
              {hours}
            </div>
          </div>
          <span className="countdown-label md:text-lg uppercase font-bold text-primary">
            H
          </span>
        </div>
        <div className="flex gap-x-2 md:gap-x-4">
          <div className="h-16 md:h-42 lg:h-52 border-2 md:border-[6px] lg:border-[8px] border-primary flex-1 flex justify-center items-center">
            <div className="countdown-number countdown-minutes text-primary text-3xl md:text-7xl lg:text-8xl font-light">
              {minutes}
            </div>
          </div>
          <span className="countdown-label md:text-lg uppercase font-bold text-primary">
            M
          </span>
        </div>
        <div className="flex gap-x-2 md:gap-x-4">
          <div className="h-16 md:h-42 lg:h-52 border-2 md:border-[6px] lg:border-[8px] border-primary flex-1 flex justify-center items-center">
            <div className="countdown-number countdown-seconds text-primary text-3xl md:text-7xl lg:text-8xl font-light">
              {seconds}
            </div>
          </div>
          <span className="countdown-label md:text-lg uppercase font-bold text-primary">
            S
          </span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
