import { Link } from "react-router-dom";
// import { IonIcon } from "@ionic/react";
import { ReactComponent as LinkedInSVG } from "../../assets/icons/icons8-linkedin.svg";

const style = {
  wrapper: `bg-slate-200`,
  wrapperInner: `m-4 pt-5 pb-8 rounded-lg md:py-24 bg-primary md:m-6 mt-0`,
  container: `px-4 md:px-16`,
  contentContainer: `md:flex gap-x-12 items-center text-white text-sm`,
  leftContent: `flex gap-x-4`,
  footerLink: ``,
  separator: `ml-2`,
};

const Footer = () => {
  return (
    <footer>
      <div className={style.wrapper}>
        <div className={style.wrapperInner}>
          <div className={style.container}>
            <div className="justify-between flex items-center">
              <div className={`flex items-center`}>
                <span className={`text-sm font-bold text-white`}>
                  Follow Us
                </span>
                <div className="gap-x-2 flex ml-4">
                  <a href="https://www.linkedin.com/company/wasshh">
                    <div className="border border-white rounded-full h-10 w-10 flex items-center justify-center p-2">
                      {/* <img
                        src="icons8-linkedin.png"
                        className="max-h-full max-w-full"
                        alt=""
                      /> */}
                      <LinkedInSVG />
                    </div>
                  </a>
                  {/* <div className="border border-white rounded-full h-10 w-10 p-1">
                    <img
                      src="icons8-linkedin.png"
                      className="max-h-full max-w-full"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
              {/* <div className={`flex items-center`}>
                <span className={`text-sm font-bold text-white`}>
                  Mobile Apps
                </span>
                <div className="gap-x-2 flex ml-4">
                  <div className="border border-white rounded-full h-10 w-10 p-1">
                    <img
                      src="icons8-linkedin.png"
                      className="max-h-full max-w-full"
                      alt=""
                    />
                  </div>
                  <div className="border border-white rounded-full h-10 w-10 p-1">
                    <img
                      src="icons8-linkedin.png"
                      className="max-h-full max-w-full"
                      alt=""
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="border-t border-white my-4 w-full"></div>
            <div className={style.contentContainer}>
              <span className="text-white font-semibold text-sm">
                © 2023 Wasshh Services Limited
              </span>
              <div className={style.leftContent}>
                <div className={style.linkContainer}>
                  <span className={style.footerLink}>
                    <Link to="/about-us">About Us</Link>
                  </span>
                </div>
                {/* <div className={style.linkContainer}>
                  <span className={style.footerLink}>
                    <Link to="#">Privacy Policy</Link>
                  </span>
                </div>
                <div className={style.linkContainer}>
                  <span className={style.footerLink}>
                    <Link to="#">Terms of Service</Link>
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
