import { Link } from "react-router-dom";

const style = {
  header: `sticky top-0 z-30 h-20 md:h-[100px]`,
  wrapper: `h-full min-h-11 w-full items-center flex`,
  nav: `w-full mx-4 md:mx-6 flex justify-between items-center`,
  left: `flex items-center`,
  logoPlaceholder: `rounded-full h-4 w-4 bg-[#38a139] mx-auto`,
  name: `text-xl md:text-3xl font-semibold block`,
  right: `flex items-center gap-x-8`,
  imagePlaceholder: `h-10 w-10 rounded-full overflow-hidden`,
  auth: `text-base font-semibold cursor-pointer`,
};

const Header = () => {
  return (
    <header className={style.header}>
      <div className={style.wrapper}>
        <div className="absolute top-0 -z-10 left-0 right-0 h-20 md:h-28 bg-gradient-to-b  from-slate-200 to-transparent"></div>
        <div className={style.nav}>
          <Link to="/">
            <div className={style.left}>
              {/* <div className={style.logoPlaceholder}></div> */}
              <div className="h-12 w-12 md:h-12 md:w-12">
                <img
                  src="logo.png"
                  alt="Wasshh Logo"
                  className="max-w-full max-h-full"
                />
              </div>
              <h2 className={style.name}>asshh</h2>
            </div>
          </Link>
          <div className={style.right}>
            <div className="flex items-center gap-x-4">
              <div className="flex items-center gap-x-4">
                <Link to="/about-us">
                  <span className={`${style.auth} hidden md:block`}>
                    About Us
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
