import { Routes, Route } from "react-router-dom";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import "./App.css";

const style = {
  app: `flex flex-col justify-between min-h-screen bg-neutral-100 text-[#212121] bg-slate-200 font-montserrat no-scrollbar`,
  main: `w-full my-auto max-md:h-full mt-0 bg-slate-200`,
};

function App() {
  return (
    <div className={style.app}>
      <Header />
      <div className={style.main}>
        <Routes>
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
