import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
// import { PlusIcon, MinusIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
// import { useNavigate } from "react-router-dom";
import HowItWorks from "../components/Home/HowItWorks";
import Services from "../components/Home/Services";
import Environment from "../components/Home/Environment";
// import Estimator from "../components/Home/Estimator";
import Download from "../components/Home/Download";
import WorkWithUs from "../components/Home/WorkWithUs";
import InfoCards from "../components/Home/InfoCards";
import CountdownTimer from "../components/Home/CountdownTimer";
import InfiniteCarousel from "../components/Home/InfiniteCarousel";

// const style = {
//   wrapper: {
//     height: "30vh",
//     width: "90vw",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundColor: "black",
//   },
//   words: {
//     display: "flex",
//     flexDirection: "row",
//     overflow: "hidden",
//   },
// };

gsap.registerPlugin(ScrollTrigger);
const Home = () => {
  const containerRef = useRef();

  // const words = ["simple", "convenient"];

  // const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const numberRef = useRef(0);

  useEffect(() => {
    console.log(numberRef, numberRef.current);
    let ctx = gsap.context((self) => {
      const text = self.selector(".text");
      console.log(text);
      // const tl = gsap.timeline({
      //   onComplete: () => {
      //     setTimeout(() => {
      //       tl.reverse();

      //       setCurrentWordIndex(
      //         (prevIndex) =>
      //           // prevIndex === words.length - 1 ? 0 : prevIndex + 1
      //           (numberRef.current =
      //             numberRef.current === words.length - 1
      //               ? 0
      //               : numberRef.current + 1)
      //       );
      //     }, 1000);
      //   },
      // });

      // tl.from(text, {
      //   opacity: 0,
      //   stagger: {
      //     each: 0.15,
      //   },
      // });

      const tl = gsap.timeline();

      tl.to(text, {
        text: "this is a custom text written to show my easy approaches to make the typewriting easy!",
        duration: 5,
        delay: 1,
        ease: "none",
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  // useEffect(() => {
  //   // const animateWord = async () => {
  //   const word = words[currentWordIndex];
  //   const text = containerRef.current;
  //   // useEffect(() => {
  //   let ctx = gsap.context((self) => {
  //     const tl = gsap.timeline({
  //       onComplete: () => {
  //         setTimeout(() => {
  //           tl.reverse();

  //           setCurrentWordIndex((prevIndex) =>
  //             prevIndex === words.length - 1 ? 0 : prevIndex + 1
  //           );
  //         }, 1000);
  //       },
  //     });

  //     tl.from(text, {
  //       opacity: 0,
  //       stagger: {
  //         each: 0.15,
  //       },
  //     });

  //     // Wait for the animation to complete
  //     // await tl.finished();

  //     // // Delay for a while before unrendering the letters
  //     // await new Promise((resolve) => setTimeout(resolve, 1000));

  //     // // Reverse the animation
  //     // tl.reverse();

  //     // // Wait for the reverse animation to complete
  //     // await tl.finished();

  //     // // Move to the next word or loop back to the first word
  //     // setCurrentWordIndex((prevIndex) =>
  //     //   prevIndex === words.length - 1 ? 0 : prevIndex + 1
  //     // );
  //     // }

  //     // Start the animation for the initial word
  //     // animateWord();
  //   }, containerRef);

  //   return () => ctx.revert();
  // }, [currentWordIndex, words]);

  return (
    <div className="">
      {/* <div className="bg-primary top-0 left-0 right-0 absolute h-[1200px]"></div> */}
      <div className="min-h-[calc(100vh-20vh)] grid max-sm:grid-rows-2 md:grid-cols-5 mb-10 bg-primary z-10 px-4 md:px-6">
        <div className="col-span-2 flex flex-col justify-center">
          <h2 className="text-white text-3xl md:text-6xl lg:text-7xl">
            A <span className="font-semibold">simple</span>, affordable and
            convenient way to do your <span className="font-bold">laundry</span>
          </h2>
        </div>
        <div className="col-span-3 flex flex-col justify-center items-center md:items-end">
          <div className="flex items-center justify-center p-16 md:p-24 rounded-full bg-gradient-to-br from-[#c0c0c0] to-[#bcc6cc]">
            <div className="flex items-center justify-center p-1 md:p-3 rounded-full bg-gray-600">
              <div className="h-40 w-40 md:h-80 md:w-80 rounded-full bg-black flex flex-col overflow-hidden">
                <div className=""></div>
                <div className="flex-1 bg-gradient-to-br from-blue-200 to-red-200"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex" ref={containerRef}>
        <span className="text"></span>
        {/* {words[currentWordIndex]
          .split("")
          .map((i) =>
            i === " " ? (
              <Letter space={true} letter={i} />
            ) : (
              <Letter space={false} letter={i} />
            )
          )} */}
      </div>

      {/* <div className="flex" ref={containerRef}>
        {words.map((word, index) => (
          <Word key={index} word={word} />
        ))}
      </div> */}

      {/* <div style={style.words}>
        {"Hello World!"
          .split("")
          .map((i) =>
            i === " " ? (
              <Letter space={true} letter={i} />
            ) : (
              <Letter space={false} letter={i} />
            )
          )}
      </div>
      <div style={style.words}>
        {"No Worries"
          .split("")
          .map((i) =>
            i === " " ? (
              <Letter space={true} letter={i} />
            ) : (
              <Letter space={false} letter={i} />
            )
          )}
      </div> */}

      <div className="py-40">
        <CountdownTimer />
      </div>

      <div className="py-10 md:py-20 flex flex-col">
        <InfiniteCarousel reversed={false} />
        <InfiniteCarousel reversed={true} />
      </div>

      <InfoCards />

      <Services />

      <HowItWorks />

      <Environment />

      <WorkWithUs />

      {/* <Estimator /> */}

      <Download />
    </div>
  );
};

export default Home;

// const Letter = ({ space, letter }) => {
//   return space === true ? (
//     <div className="text">&nbsp;</div>
//   ) : (
//     <div className="text" style={style.letter}>
//       {letter}
//     </div>
//   );
// };

// const Word = ({ word }) => {
//   return (
//     <div className="word flex">
//       {word.split("").map((letter, index) => (
//         <Letter key={index} letter={letter} />
//       ))}
//     </div>
//   );
// };

// const Letter = ({ letter }) => {
//   return (
//     <div className="text" style={style.letter}>
//       {letter}
//     </div>
//   );
// };
