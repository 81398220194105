import React, { useState } from "react";

const Download = () => {
  const [active, setActive] = useState("customer");

  // useEffect(() => {
  //   let ctx = gsap.context(() => {
  //     // Create a timeline for animations
  //     const tl = gsap.timeline({
  //       scrollTrigger: {
  //         trigger: ".info-card-container", // Use an appropriate selector for the trigger
  //         start: "top 5%", // Pin the container 10% from the top
  //         pin: true,
  //         pinSpacing: false,
  //         scrub: true,
  //         // Add other ScrollTrigger options as needed
  //       },
  //     });

  //     // Animation for the first InfoCard
  //     tl.from(".animate-first", {
  //       opacity: 0,
  //       y: "20%",
  //       stagger: 0.2,
  //     })
  //       .from(".animate-second", {
  //         opacity: 0,
  //         y: "20%",
  //       })
  //       .from(".animate-third", {
  //         opacity: 0,
  //         y: "20%",
  //       });

  //     // Animation for the second InfoCard (similar animations for the third)
  //     tl.from(".animate-second", {
  //       opacity: 0,
  //       y: "20%",
  //     }).from(".animate-third", {
  //       opacity: 0,
  //       y: "20%",
  //     });

  //     // Repeat the above for the third InfoCard

  //     // Unpin the container when the animations are done
  //     tl.to(pinContainer.current, {
  //       scrollTrigger: {
  //         trigger: pinContainer.current,
  //         start: "bottom bottom",
  //         end: "+=100%", // Adjust the end value as needed
  //         pin: false,
  //         pinSpacing: false,
  //         scrub: true,
  //         // Add other ScrollTrigger options as needed
  //       },
  //     });
  //   }, pinContainer);

  //   return () => ctx.revert();
  // }, []);

  return (
    <div className="my-32">
      <div className="flex flex-col items-center mb-8">
        <h2 className="text-4xl mb-5">Download our app</h2>
        <div className="flex gap-x-4 text-sm">
          {["customer", "washer", "rider"].map((item) => (
            <div
              className={`px-4 capitalize cursor-pointer py-2 border border-black ${
                active === item ? "bg-black text-white" : "text-black"
              } rounded-full`}
              onClick={() => setActive(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="mx-5 md:mx-32">
        <div
          className={`${
            active === "customer"
              ? "bg-blue-500"
              : active === "washer"
              ? "bg-primary"
              : "bg-yellow-400"
          } h-[500px] rounded-xl px-5 md:px-20 pt-20 md:grid grid-cols-2 gap-x-10`}
        >
          <div
            className={`${active === "rider" ? "text-black" : "text-white"}`}
          >
            <h3 className="text-3xl md:text-6xl font-bold mb-10">
              {active === "customer"
                ? "Request in seconds, pick up in minutes"
                : active === "washer"
                ? "More visibility, more reach"
                : "Become the point of contact between Wasshh and the world"}
            </h3>
            {active === "customer" ? (
              <p className="text-lg mb-2">Start cleaning your laundry now.</p>
            ) : (
              active === "washer" && (
                <p className="text-lg mb-2">
                  Become a washer and gain more reach and more visibility.
                </p>
              )
            )}
            <p className="text-lg">
              Scan the QR code with your phone camera to download the Wasshh
              app. Available for iOS and Android devices.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
