import React, { useEffect, useRef } from "react";
import gsap from "gsap";
// eslint-disable-next-line
import ScrollTrigger from "gsap/ScrollTrigger";
import { IoMoonOutline, IoPhonePortraitOutline } from "react-icons/io5";
import { HiOutlineCursorArrowRipple } from "react-icons/hi2";
import { GiKitchenScale, GiSwapBag } from "react-icons/gi";
import { BsClockHistory } from "react-icons/bs";
import { GrDeliver } from "react-icons/gr";
import { Ri24HoursLine } from "react-icons/ri";

const style = {
  icon: `text-primary h-5 w-5`,
};

const HowItWorks = () => {
  const steps = [
    {
      title: "Schedule your collection",
      subtitle: "Flexible",
      content:
        "Plan your day with ease. Choose a collection and delivery time at your convenience.",
      list: [
        {
          icon: <HiOutlineCursorArrowRipple className={style.icon} />,
          text: "Book with our mobile app",
        },
        {
          icon: <IoMoonOutline className={style.icon} />,
          text: "Weekend and evening slots available",
        },
      ],
      image: "stepOne.avif",
    },
    {
      title: "Pack your laundry",
      subtitle: "Quick & Easy",
      content:
        "Pack your items in a disposable bag. Use one bag per service. Our rider will transfer them to reuseable laundry heap bags which you can keep for your nex order.",
      list: [
        {
          icon: <GiSwapBag className={style.icon} />,
          text: "Pack one bag per service",
        },
        {
          icon: <GiKitchenScale className={style.icon} />,
          text: "No need to count or weigh your items",
        },
      ],
      image: "stepTwo.avif",
    },
    {
      title: "Wait for our rider",
      subtitle: "Transparent",
      content:
        "You'll recieve a notification when our rider is nearby. They will collect your bags and take them to your local cleaning facility.",
      list: [
        {
          icon: <BsClockHistory className={style.icon} />,
          text: "Regular order updates",
        },
        {
          icon: <GrDeliver className={style.icon} />,
          text: "Live rider tracking",
        },
      ],
      image: "stepThree.avif",
    },
    {
      title: "Relax while we take care of your laundry",
      subtitle: "Convenient",
      content:
        "Your local partner facility will clean your items with utmost care. Our driver will then deliver thm back to you whenever you like. You're in full control of your delivery and can always reschedule if not at home.",
      list: [
        {
          icon: <Ri24HoursLine className={style.icon} />,
          text: "24h turnaround time",
        },
        {
          icon: <BsClockHistory className={style.icon} />,
          text: "Real-time order status",
        },
        {
          icon: <IoPhonePortraitOutline className={style.icon} />,
          text: "Easy to reschedule",
        },
      ],
      image: "stepFour.avif",
    },
  ];

  return (
    <div className="mx-5 md:mx-20 my-20">
      <div className="flex flex-col items-center gap-y-5 mb-20">
        <h2 className="text-4xl">How we work</h2>
      </div>
      <div className="flex flex-col gap-y-20 md:gap-y-52">
        {steps.map((step, index) => (
          <WorkStep
            reverse={index % 2 !== 0}
            key={index}
            index={index}
            step={step}
          />
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;

const WorkStep = ({ reverse, index, step }) => {
  const stepRef = useRef();

  useEffect(() => {
    let ctx = gsap.context((self) => {
      const stepImage = self.selector(".step-bg");
      gsap.to(stepImage, {
        scrollTrigger: {
          trigger: stepImage,
          // toggleActions: "restart pause reverse pause",
          scrub: 1,
          start: "top bottom",
          end: "center top",
        },
        // lazy: false,
        backgroundPosition: "0px -100px",
        ease: "none",
      });
    }, stepRef);

    return () => ctx.revert();
  }, []);

  return (
    <div className="flex flex-col-reverse md:grid grid-cols-5" ref={stepRef}>
      <div
        className={`col-span-2 flex flex-col justify-between py-10 ${
          reverse ? "md:order-last" : ""
        }`}
      >
        <div className="border-2 w-20 h-20 border-black rounded-full items-center justify-center hidden md:flex">
          <span className="text-6xl font-light">{index + 1}</span>
        </div>
        <div>
          <h3 className="text-lg text-primary uppercase mb-5">
            {step.subtitle}
          </h3>
          <h2 className="text-2xl md:text-4xl mb-5">{step.title}</h2>
          <p className="text-sm md:text-lg mb-6">{step.content}</p>
          <ul className="flex flex-col gap-y-3">
            {step.list.map((item, index) => (
              <li className="flex items-center gap-x-4">
                {item.icon}
                <span className="text-sm md:text-base font-semibold">
                  {item.text}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="col-span-3">
        <div
          className={`${
            reverse ? "md:mr-32" : "md:ml-32"
          } bg-black h-80 md:h-[600px] rounded-xl overflow-hidden`}
        >
          <div
            className={`h-[130%] w-[130%] bg-no-repeat bg-cover step-bg`}
            style={{ backgroundImage: `url(${step.image})` }}
          ></div>
        </div>
      </div>
    </div>
  );
};
