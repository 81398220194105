import React, { useEffect, useRef } from "react";
import gsap from "gsap";

const InfiniteCarousel = ({ reversed }) => {
  const style = {
    stbItem: `p-0 text-3xl md:text-6xl lg:text-7xl font-light stb-item inline-block h-full mr-[5rem] tracking-[0.3rem]`,
  };

  const stbContainerRef = useRef();

  // useEffect(() => {
  //   const speed = 1; // (in pixels per second)

  //   // const links = document.querySelectorAll(".stb-item");
  //   const links = stbContainerRef?.current?.querySelectorAll(".stb-item");

  //   const tl = horizontalLoop(links, {
  //     speed: speed,
  //     reversed: reversed,
  //     repeat: -1,
  //   });

  //   links.forEach((link) => {
  //     let isHovered = false;

  //     link.addEventListener("mouseenter", () => {
  //       isHovered = true;
  //       animateFontWeight(link, 800, 500); // Animate to font weight 800 in 0.5 seconds
  //       gsap.to(tl, { timeScale: 0, overwrite: true });
  //     });

  //     link.addEventListener("mouseleave", () => {
  //       isHovered = false;
  //       animateFontWeight(link, 300, 500); // Animate back to normal font weight in 0.5 seconds

  //       // Delay the timeline resume to ensure smoothness
  //       setTimeout(() => {
  //         if (!isHovered) {
  //           gsap.to(tl, { timeScale: -1, overwrite: true });
  //         }
  //       }, 500);
  //     });
  //   });
  // }, [reversed]);

  const timelineRef = useRef(null);

  useEffect(() => {
    const speed = 1; // (in pixels per second)

    const links = stbContainerRef.current.querySelectorAll(".stb-item");

    const tl = horizontalLoop(links, {
      speed: speed,
      reversed: reversed,
      repeat: -1,
    });

    timelineRef.current = tl;

    links.forEach((link) => {
      link.addEventListener("mouseenter", () => {
        // animateFontWeight(link, 800, 0.5); // Animate to font weight 800 in 0.5 seconds
        tl.pause(); // Pause the timeline when hovering
      });

      link.addEventListener("mouseleave", () => {
        // animateFontWeight(link, 300, 0.5); // Animate back to normal font weight in 0.5 seconds
        setTimeout(() => {
          tl.play(); // Resume the timeline after a delay to ensure smoothness
        }, 500);
      });
    });

    // return () => {
    //   // Clean up event listeners when component unmounts
    //   links.forEach((link) => {
    //     link.removeEventListener("mouseenter");
    //     link.removeEventListener("mouseleave");
    //   });
    // };
  }, [reversed]);

  // const animateFontWeight = (element, targetWeight, duration) => {
  //   const initialWeight = parseInt(getComputedStyle(element).fontWeight);
  //   // const steps = 10; // Number of steps for the animation

  //   gsap.to(element, {
  //     duration: duration / 1000,
  //     onUpdate: (progress) => {
  //       const currentWeight = Math.round(
  //         initialWeight + progress * (targetWeight - initialWeight)
  //       );
  //       element.style.fontWeight = currentWeight;
  //     },
  //   });
  // };

  // const animateFontWeight = (element, targetWeight, duration) => {
  //   const initialWeight = parseInt(getComputedStyle(element).fontWeight);

  //   gsap.to(element, {
  //     duration: duration,
  //     fontWeight: targetWeight,
  //     ease: "Power1.easeOut",
  //     onUpdate: () => {},
  //   });
  // };

  const horizontalLoop = (items, config) => {
    // Your horizontalLoop function code goes here (copy and paste it)
    items = gsap.utils.toArray(items);
    config = config || {};
    let tl = gsap.timeline({
        repeat: config.repeat,
        paused: config.paused,
        defaults: { ease: "none" },
        onReverseComplete: () =>
          tl.totalTime(tl.rawTime() + tl.duration() * 100),
      }),
      length = items.length,
      startX = items[0].offsetLeft,
      times = [],
      widths = [],
      xPercents = [],
      curIndex = 0,
      pixelsPerSecond = (config.speed || 1) * 100,
      snap =
        config.snap === false ? (v) => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
      totalWidth,
      curX,
      distanceToStart,
      distanceToLoop,
      item,
      i;
    gsap.set(items, {
      // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
      xPercent: (i, el) => {
        let w = (widths[i] = parseFloat(gsap.getProperty(el, "width", "px")));
        xPercents[i] = snap(
          (parseFloat(gsap.getProperty(el, "x", "px")) / w) * 100 +
            gsap.getProperty(el, "xPercent")
        );
        return xPercents[i];
      },
    });
    gsap.set(items, { x: 0 });
    totalWidth =
      items[length - 1].offsetLeft +
      (xPercents[length - 1] / 100) * widths[length - 1] -
      startX +
      items[length - 1].offsetWidth *
        gsap.getProperty(items[length - 1], "scaleX") +
      (parseFloat(config.paddingRight) || 0);
    for (i = 0; i < length; i++) {
      item = items[i];
      curX = (xPercents[i] / 100) * widths[i];
      distanceToStart = item.offsetLeft + curX - startX;
      distanceToLoop =
        distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
      tl.to(
        item,
        {
          xPercent: snap(((curX - distanceToLoop) / widths[i]) * 100),
          duration: distanceToLoop / pixelsPerSecond,
        },
        0
      )
        .fromTo(
          item,
          {
            xPercent: snap(
              ((curX - distanceToLoop + totalWidth) / widths[i]) * 100
            ),
          },
          {
            xPercent: xPercents[i],
            duration:
              (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
            immediateRender: false,
          },
          distanceToLoop / pixelsPerSecond
        )
        .add("label" + i, distanceToStart / pixelsPerSecond);
      times[i] = distanceToStart / pixelsPerSecond;
    }
    const toIndex = (index, vars) => {
      vars = vars || {};
      Math.abs(index - curIndex) > length / 2 &&
        (index += index > curIndex ? -length : length); // always go in the shortest direction
      let newIndex = gsap.utils.wrap(0, length, index),
        time = times[newIndex];
      // prettier-ignore
      if ((time > tl.time()) !== (index > curIndex)) {
          // if we're wrapping the timeline's playhead, make the proper adjustments
          vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
          time += tl.duration() * (index > curIndex ? 1 : -1);
        }
      curIndex = newIndex;
      vars.overwrite = true;
      return tl.tweenTo(time, vars);
    };
    tl.next = (vars) => toIndex(curIndex + 1, vars);
    tl.previous = (vars) => toIndex(curIndex - 1, vars);
    tl.current = () => curIndex;
    tl.toIndex = (index, vars) => toIndex(index, vars);
    tl.times = times;
    tl.progress(1, true).progress(0, true); // pre-render for performance
    if (config.reversed) {
      tl.vars.onReverseComplete();
      tl.reverse();
    }
    return tl;
  };

  return (
    <div className="relative flex items-center overflow-hidden h-32">
      <div
        className="relative whitespace-nowrap p-0 will-change-transform flex flow-row items-center h-full"
        ref={stbContainerRef}
      >
        {[
          "Dry Clean",
          "Wash",
          "Iron",
          "Starch",
          "Laundromart",
          "Clean",
          "Fresh",
          "Delivery",
          "Affordable",
        ].map((item, index) => (
          <div href="#" className={style.stbItem}>
            <div className="flex items-center gap-x-16">
              <span>{item}</span>
              <span className="flex justify-center text-xl font-bold items-center h-16 w-16 border-2 border-black rounded-full">
                {index + 1}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfiniteCarousel;
