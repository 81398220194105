import React, { useEffect, useRef, useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

const Environment = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const sectionRef = useRef();

  useEffect(() => {
    const section = sectionRef?.current;
    const addEventListeners = () => {
      section.addEventListener("mouseenter", mEnter);
      section.addEventListener("mouseleave", mLeave);
    };

    const removeEventListeners = () => {
      section.removeEventListener("mouseenter", mEnter);
      section.removeEventListener("mouseleave", mLeave);
    };

    const mLeave = () => {
      setShow(false);
    };

    const mEnter = () => {
      setShow(true);
    };

    addEventListeners();
    return () => removeEventListeners();
  }, [sectionRef]);

  return (
    <div
      className="bg-[#e3d8d8] px-5 md:px-8 py-20 md:py-40 my-40 overflow-hidden"
      ref={sectionRef}
    >
      <Cursor show={show} sectionRef={sectionRef} />
      <div className="md:grid grid-cols-3">
        <div className="col-span-2 max-sm:mb-8">
          <h2 className="text-5xl md:text-8xl text-white">
            A convinient laundry solution that helps{" "}
            <span className="text-emerald-400">protect the environment.</span>
          </h2>
        </div>
        <div className="flex flex-col justify-center gap-y-10">
          <ul className="text-primary flex flex-col gap-y-4 mb:gap-y-6 font-semibold text-base md:text-2xl">
            <li>
              <span>Zero-emission delivery vehicles</span>
            </li>
            <li>
              <span>Efficient water use</span>
            </li>
            <li>
              <span>Trustworthy local cleaners</span>
            </li>
          </ul>
          <button
            className="w-52 px-8 py-4 rounded-xl text-white text-xl bg-primary"
            onClick={() => navigate("/about-us")}
          >
            About Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Environment;

const Cursor = ({ show, sectionRef }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const cursorRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const section = sectionRef.current;
    const addEventListeners = () => {
      section.addEventListener("mousemove", mMove);
    };
    const removeEventListeners = () => {
      section.removeEventListener("mousemove", mMove);
    };

    const mMove = (el) => {
      console.log({ x: el.clientX, y: el.clientY });
      setPosition({ x: el.clientX, y: el.clientY });
    };
    addEventListeners();
    return () => removeEventListeners();
  }, [sectionRef]);

  return (
    <div
      className={`cursor border-2 border-primary ${
        show ? "show" : "h-0 w-0 opacity-0"
      }`}
      ref={cursorRef}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
      onClick={() => navigate("/about-us")}
    >
      <ArrowRightIcon
        className={`text-primary cursor-pointer stroke-0 ${
          show ? "block h-20 w-20" : "hidden"
        }`}
      />
      {/* <span className={`z-20 w-fit`}>Click to</span> */}
    </div>
  );
};
